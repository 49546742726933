



















import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator"
import { FormatType, Format, formatDisplay } from "@/select"
import { Sizes } from "@/adkernel/display"

@Component({
	name: "SizeCheckboxSelector",
	components: {},
})
export default class extends Vue {
	@Prop() akversion!: number
	@Prop({ default: () => [] }) private readonly available!: Format[]
	@Prop({ default: false }) private readonly loading!: boolean
	@Prop({ default: false }) private readonly multiple!: boolean
	@Prop() value!: number | number[] | null
	@Prop({ default: false }) private readonly multipleInventories!: boolean

	get Sizes() {
		return Sizes
	}

	private isAvailable(f: number) {
		return this.available.includes(f)
	}
}
