





import { Component, Prop, Vue } from "vue-property-decorator"
import { lookupLocations } from "@/select/api/location"
import { LocationData, Lookup } from "@/select/types"

@Component({
	name: "CountrySelector",
})
export default class extends Vue {
	@Prop() value!: LocationData | LocationData[] | null
	@Prop({ default: false }) multiple!: boolean
	private list: Lookup[] = []
	async mounted() {
		this.list = (await lookupLocations({ country: 1 })).data
		if (this.list.length === 1) this.$emit("input", [this.list[0].id])
	}

	private dispatch(e) {
		this.$emit("input", e)
	}
}
