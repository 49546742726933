import request from "@/utils/request"
import { ITeamData } from "./types"

export const defaultTeamData: ITeamData = {
	id: 0,
	name: "",
	advertisers: [],
	agency: null,
}

export const createTeam = (data: any) =>
	request({
		url: "/teams/",
		method: "post",
		data,
	})

export const getTeams = (params: any) =>
	request({
		url: "/teams/",
		method: "get",
		params,
	})

export const getTeam = (id: number, params: any) =>
	request({
		url: `/teams/${id}/`,
		method: "get",
		params,
	})

export const updateTeam = (id: number, data: any) =>
	request({
		url: `/teams/${id}/`,
		method: "put",
		data,
	})

export const lookupTeams = (params: any) =>
	request({
		url: "/teams/lookup/",
		method: "get",
		params,
	})
