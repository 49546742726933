


import Component, { mixins } from "vue-class-component"
import { DemandSideSelectorMixin } from "@/mixins"
import { getPricingModels } from "@/select/api"

@Component({
	name: "PricingModelSelector",
})
export default class extends mixins(DemandSideSelectorMixin) {
	listCall = getPricingModels
}
