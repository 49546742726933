










import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator"
import { AgencyData, Lookup } from "@/select/types"

@Component({
	name: "AgencySelector",
})
export default class extends Vue {
	@Prop({ default: () => [] }) private list!: Lookup[]
	@Prop({ default: null }) readonly value!: AgencyData | AgencyData[] | null
	@Prop({ default: false }) private multiple!: boolean
}
