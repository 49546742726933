












import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator"
import { Context, Lookup } from "@/select/types"

@Component({
	name: "ContextSelector",
})
export default class extends Vue {
	@Prop({ default: () => [] }) private list!: Context[]
	@Prop({ default: null }) readonly value!: Context | Context[] | null
	@Prop({ default: false }) disabled!: boolean
	@Prop({ default: false }) readonly multiple!: boolean
}
