


import Component, { mixins } from "vue-class-component"
import { getTeams } from "@/api/teams"
import { DemandSideSelectorMixin } from "@/mixins"

@Component({
	name: "TeamSelector",
})
export default class extends mixins(DemandSideSelectorMixin) {
	listCall = getTeams
}
