












import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { getPublishers } from "@/select/api"
import { PublisherData } from "@/select/types"

@Component({
	name: "PublisherSelector",
})
export default class extends Vue {
	@Prop({ default: false }) private readonly multiple!: boolean
	@Prop({ default: "large" }) private readonly size!: string
	@Prop({ default: null }) readonly value!:
		| PublisherData
		| PublisherData[]
		| null

	private list: PublisherData[] = []

	async mounted() {
		this.list = (await getPublishers({ page_size: 0 })).data
	}
}
