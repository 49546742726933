



























import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { groupInventories } from "@/select/inventory"
import { InventoryData } from "@/select/types"

@Component({
	name: "InventorySelector",
})
export default class extends Vue {
	@Prop({ default: null }) readonly value!: any
	@Prop({ default: false }) private readonly multiple!: boolean
	@Prop({ default: () => [] }) private readonly list!: InventoryData[]
	@Prop({ default: "large" }) private readonly size!: string
	@Prop({ default: false }) private readonly loading!: boolean

	get groups() {
		if (!this.list.length) return []
		const admin = this.list[0].agencies !== undefined
		let list = this.list
		if (admin)
			list = list.map((i) => {
				let name = i.name
				if (!!i.agencies && i.agencies.length > 0)
					name = `${i.name} (${i.agencies.join(", ")})`
				return { ...i, name }
			})
		return groupInventories(list)
	}

	get siteNames() {
		return [...new Set(this.value.websites.map((s) => s.split(" ")[0]))]
	}
}
