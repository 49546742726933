


















import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator"
import { SizeMap, Sizes } from "@/adkernel/display"

@Component({
	name: "SizeSelector",
	components: {},
})
export default class extends Vue {
	@Prop({ default: () => [] }) private readonly availableSizes!: number[]
	@Prop({ default: false }) private readonly loading!: boolean
	@Prop({ default: false }) private readonly multiple!: boolean
	@Prop({ default: "large" }) private readonly size!: string
	@PropSync("value", { required: true }) private readonly syncedValue!:
		| number
		| number[]

	get SizeMap() {
		return SizeMap
	}
}
