



















import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator"
import { FormatType, Format, formatDisplay } from "@/select"

@Component({
	name: "FormatCheckboxSelector",
	components: {},
})
export default class extends Vue {
	@Prop({ default: () => [] }) private readonly available!: Format[]
	@Prop({ default: null }) formatType!: FormatType | null
	@Prop({ default: false }) private readonly loading!: boolean
	@Prop({ default: false }) private readonly multiple!: boolean
	@Prop() value!: number | number[] | null
	@Prop({ default: false }) private readonly multipleInventories!: boolean

	private isAvailable(f: number) {
		return this.available.includes(f)
	}

	get formatDisplay() {
		return formatDisplay
	}

	get formats() {
		const displayKeys = Array.from(Array(7).keys()).map((i) => i + 1)
		const videoKeys = Array.from(Array(3).keys()).map((i) => i + Format.Bumper)
		if (this.formatType === FormatType.Display) return displayKeys
		if (this.formatType === FormatType.Video) return videoKeys
		return displayKeys.concat(videoKeys)
	}
}
