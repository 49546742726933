




















import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator"
import { Format, formatDisplay } from "@/select"

@Component({
	name: "FormatSelector",
	components: {},
})
export default class extends Vue {
	@Prop({ default: () => [] }) private readonly availableFormats!: Format[]
	@Prop({ default: false }) private readonly loading!: boolean
	@Prop({ default: false }) private readonly multiple!: boolean
	@Prop({ default: "large" }) private readonly size!: string
	@PropSync("value", { required: true }) private readonly syncedValue!:
		| number
		| number[]

	get formatDisplay() {
		return formatDisplay
	}
}
