// Order affects display order in UI
export const Sizes = [
	{ id: 108, label: "970x250" },
	{ id: 18, label: "300x600" },
	{ id: 2, label: "300x250" },
	{ id: 4, label: "728x90" },
	{ id: 42, label: "320x50" },
	{ id: 1, label: "120x600" },
	{ id: 6, label: "160x600" },
]

export const SizeMap = {
	108: "970x250",
	18: "300x600",
	2: "300x250",
	4: "728x90",
	42: "320x50",
	1: "120x600",
	6: "160x600",
}
export function sizeDisplay(s: number) {
	return SizeMap[s] || `Unknown (${s})`
}
